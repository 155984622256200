<template>
  <div class="fileButtonGroup">
     <div class="name">
      <el-tooltip
        effect="dark"
        placement="top"
        :content="scope.row.fileName">
        <span >{{scope.row.fileName}}</span>
      </el-tooltip>
    </div>
    <span class="name" style="width:calc()">
       <el-tooltip

            effect="dark"
            :content="
             scope.row.fileName
            "
            placement="top"
            :disabled="disabledarr"
            :enterable="false"

          >
            <div class="fileName"  ref="valuebox">{{getFileName(scope.row.fileName,'fileName')}}</div>
          </el-tooltip>

      <div class="suffix">{{getFileName(scope.row.fileName,'suffix')}}</div> </span>
    <el-upload
      action=""
      class="upload-demo"
      :http-request="uploadFile"
      :show-file-list="false"
      accept=".doc,.docx"
      v-if="type!=='details'"
    >
      <IconButton
        :content="fileId ? '重新上传' : '上传'"
        :icon="
          fileId ? 'iconfont iconzhongxinshangchuan' : 'iconfont iconshangchuan'
        "
      ></IconButton>
    </el-upload>
    <IconButton
        content="删除"
        icon="
         iconfont iconshanchu1
        "
        v-if="scope.row.fileId&&type!=='details'"
        @click.prevent="delFile"
      ></IconButton>
  </div>
</template>

<script>
import { fileAPi } from '@/api/fileApi'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  components: { IconButton },
  props: {
    scope: Object,
    fileId: String,
    index: Number,
    fileName: String,
    type: String
  },
  data () {
    return {
      disabledarr: true
    }
  },
  // 计算属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取内容宽度
    getwidth () {
      this.dom = this.$refs.valuebox
      if (this.dom) {
        this.disabledarr = this.dom.offsetWidth >= this.dom.scrollWidth
        // this.dom.forEach((item, index) => {
        //   this.$set(
        //     this.disabledarr,
        //     index,
        //     item.offsetWidth >= item.scrollWidth
        //   )
        // })
      }
    },
    getFileName (fileName, type) {
      if (fileName) {
        const fileArr = fileName.split('.')
        this.$nextTick(() => {
          this.getwidth()
        })
        if (type === 'fileName') {
          fileArr.splice(fileArr.length - 1, 1)
          return fileArr.join()
        } else if (type === 'suffix') {
          return '.' + fileArr[fileArr.length - 1]
        }
      }
    },
    // 删除附件
    delFile () {
      this.$emit('delFile', this.scope.$index)
    },
    // 上传附件
    uploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'WSF')
      fileAPi.file(formData).then((res) => {
        if (res.success) {
          this.success('文件上传成功')
          this.$emit('uploadFile', res.data, this.scope.$index)
        } else {
          this.error('文件上传成功')
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.fileButtonGroup {
  display: flex;
  align-items: center;
  .name {
    display: flex;
    align-items: center;
    text-align: left;
    width: calc(100% - 100px);
    // color: #2862E7;
  }
  .content {
    width: 100px;
  }
}
.file {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
